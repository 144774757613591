// Load Bootstrap JS
import bootstrap from "bootstrap"
import $ from "jquery";

// Load Styles
import '../scss/app.scss';

// App code
console.log(`Hello ${process.env.HELLO}`);

$('.rent-domain').click(function () {
	gtag('event', 'rent_domain_clicked');
});

$('.buy-domain').click(function () {
	gtag('event', 'buy_domain_clicked');
});
